* {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #222831;
    height: 100vh;
    font-family: sans-serif;
    text-align: center;
    color: #fff;
  }
  