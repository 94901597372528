.img-wrapper {
    overflow: hidden;
}

img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
}

img.hover-zoom:hover {
    transform: scale(1.25);
}